.site-heade {
    position: relative;
    padding: 24px 0;
}

.header-illustration {
	position: absolute;
	top: -80px;
	right: -160px;
}

.site-heade-inner {
    position: relative; /* To display all elements above the background color */
    display: flex;
    justify-content: space-between;
    align-items: center;
}