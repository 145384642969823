.hero {
    position: relative;
    text-align: center;
    padding-top: 40px;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        height: 230px;
        width: 80%;
        background-size: cover;
        background-position: 50% 50%;
        background-image: url(assets/img/capa.png);

        // background: linear-gradient(to top right, color(primary, 1) 0, color(primary, 2) 100%);
    }
}

.hero-inner {
    position: relative; /* To display all elements above the background color */
}

.brand.header-brand img {
    width: 90px;
}

.hero-title {
    @include font-weight(bold);
}

.hero-paragraph {
    margin-bottom: 32px;
}

.hero-illustration {
    margin-top: 40px;
    padding-bottom: 40px;

	img,
    svg {
        max-width: 320px;
        height: auto;
        margin: 0 auto;
        overflow: visible;
    }
}

@include media( '>small' ) {
    .hero-illustration {
        width: 100%;

        .play {
            position: relative;
            border-radius: 50px;
            cursor: pointer;

            animation: pulse 0.7s infinite;
            animation-direction: alternate;

            > img {
                width: 90px;
                height: 90px;

                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.43);
                border-radius: 50%;
            }
        }
    }
}

@include media( '>medium' ) {

    .hero {
        text-align: left;
        padding-top: 211px;
        padding-bottom: 80px;

        &::before {
            left: 620px;
            height: 572px;
            width: 100%;
        }
    }

    .hero-inner {
        /* Split hero in two half */
        display: flex;
    }

    .hero-copy {
        padding-right: 48px;
        min-width: 512px;
    }

    .hero-illustration {
       
        width: 100%;
        margin-top: -68px;
        margin-left: -284px;
        padding-bottom: 0;

        .play {
            position: absolute;
            top: 91px;
            right: 90px;
            border-radius: 50px;
            cursor: pointer;

            animation: pulse 0.7s infinite;
            animation-direction: alternate;

            > img {
                width: 90px;
                height: 90px;

                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.43);
                border-radius: 50%;
            }
        }

		img,
        svg {
            max-width: none;
            width: 528px;
            height: 489px;
        }
    }
}

@include media( '>large' ) {

    .hero {

        &::before {
            left: auto;
            width: 43%;
        }
    }

    .hero-copy {
        padding-right: 88px;
        min-width: 552px;
    }
}


@-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale(1);
      -webkit-filter: brightness(100%);
    }
    100% {
      -webkit-transform: scale(1.1);
      -webkit-filter: brightness(200%);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      filter: brightness(100%);
    }
    100% {
      transform: scale(1.1);
      filter: brightness(90%);
    }
  }
