.feature-extended {
    padding-top: 48px;
}

.feature-extended-image,
.feature-extended-body {
    width: 100%;
}

.feature-extended-image {
    margin-bottom: 24px;

	img,
    svg {
        width: 100%;
        max-width: 320px;
        height: auto;
        margin: 0 auto;
        overflow: visible;
    }
}

.feature-extended-body {
    text-align: center;
}

.feature-extended-body h3 {
    margin-top: 0px;
}

.list-description p {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    margin-bottom: 10px;
}

@include media( '>medium' ) {

    .features-extended {

        .section-paragraph {
            padding-left: 72px;
            padding-right: 72px;
            margin-bottom: 0;
        }
    }

    .feature-extended {
        display: flex;
        align-items: center;
        padding-top: 72px;

        &:nth-child(even) {

            .feature-extended-image {
                order: 1;
            }
        }
    }

    .feature-extended-image,
    .feature-extended-body {
        width: auto;
        padding: 0 24px;
    }

    .feature-extended-image {
        margin-bottom: 0;

		img,
        svg {
            max-width: none;
            width: 320px;
        }
    }

    .feature-extended-body {
        text-align: left;
    }
}

@include media( '>large' ) {

    .feature-extended {
        padding-left: 40px;
        padding-right: 40px;
    }

    .feature-extended-image,
    .feature-extended-body {
        padding: 0 40px;
    }

    .feature-extended-image {

		img,
        svg {
            width: 480px;
        }
    }
}

#features {
  background: #fff;
  margin-bottom: 70px;
}

#features .icon {
  display: inline-block;
  width: 60px;
  height: 34px;
  border-radius: 4px;
  text-align: center;
  position: relative;
  z-index: 1;
}

#features .content-left span {
  float: right;
}

#features .content-right span {
  float: left;
}

#features .box-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 2px 25px #F4F4F4;
  padding: 15px;
  line-height: 22px;
  margin-top: 30px;
  border-radius: 8px;
  background-color: #fff;
  -webkit-transition: all .3s ease-in-out;
  -mox-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  position: relative;
  top: 0;
}

#features .box-item .icon {
  text-align: center;
  margin: 12px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#features .box-item .icon i {
  color: #2294fb;
  font-size: 30px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#features .box-item .text h4 {
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin: 0 0 10px;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#features .box-item .text p {
  font-size: 14px;
  text-align: center;
  line-height: 18px;
}

#features .box-item:hover {
  box-shadow: 0 10px 22px 10px rgba(27, 38, 49, 0.1);
}

#features .box-item:hover h4 {
  color: #F63854;
}

#features .show-box {
  margin-top: 50px;
}

#features .show-box img {
  width: 100%;
}

.shape {
  height: 1px;
  margin: 0 auto 30px;
  position: relative;
  width: 60px;
  background-color: #F63854;
}