.is-boxed {
    background: color(bg, 2);
}

.body-wrap {
    background: color(bg, 1);
    overflow: hidden;
    /* Sticky footer */
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.boxed-container {
    max-width: 1440px;
    margin: 0 auto;
    @include shadow;
}

