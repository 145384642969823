.site-footer {
    @include font-size(8, mobile, true, true, true);
    @if ( get-font-size(8, desktop) != get-font-size(8, mobile) ) {
        @include media( '>medium' ) {
            @include font-size(8, desktop, true, true, true);
        }
    }
    color: color(typography, 3);
    background: color(typography, 1);

    a {
        @include anchor-aspect(footer);
    }
}

.footer-brand img {
    width: 90px;
}

.site-footer-inner {
    position: relative; /* To display all elements above the background color */
    display: flex;
    flex-wrap: wrap;
    padding-top: 40px;
    padding-bottom: 40px;

    &.has-top-divider {

        &::before {
            background: rgba(color(bg,1), .08);
        }
    }
}

.footer-brand,
.footer-links,
.footer-social-links,
.footer-copyright {
    flex: none;
    width: 100%;
    display: inline-flex;
    justify-content: center;
}

.footer-brand,
.footer-links,
.footer-social-links {
    margin-bottom: 24px;
}

.footer-links,
.footer-social-links {

    li {

        + li {
            margin-left: 16px;
        }
    }
}

.footer-social-links {

    li {
        display: inline-flex;

        a {
            padding: 8px;
        }
    }
}

@include media( '>medium' ) {

    .site-footer-inner {
        justify-content: space-between;
    }

    .footer-brand,
    .footer-links,
    .footer-social-links,
    .footer-copyright {
        flex: 50%;
    }

    .footer-brand,
    .footer-copyright {
        justify-content: flex-start;
    }

    .footer-links,
    .footer-social-links {
        justify-content: flex-end;
    }

    .footer-links {
        order: 1;
        margin-bottom: 0;
    }
}
