.donation {
    position: relative;
    padding-top: 60px;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		bottom: -15%;
		width: 100%;
		height: 0;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSI0MjciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICA8ZGVmcz4gICAgPGxpbmVhckdyYWRpZW50IHgxPSI1MCUiIHkxPSIxMDAlIiB4Mj0iNTAlIiB5Mj0iMCUiIGlkPSJhIj4gICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjRjBGNEZFIiBzdG9wLW9wYWNpdHk9IjAiIG9mZnNldD0iMCUiLz4gICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjRjBGNEZFIiBvZmZzZXQ9IjEwMCUiLz4gICAgPC9saW5lYXJHcmFkaWVudD4gIDwvZGVmcz4gIDxwYXRoIGQ9Ik0wIDc5My4zOThjNDMuODU1LTEzLjM2IDEzMC40OC0yMC41MDIgMjU5LjU4Ni0yMS40MjVDNTIxLjM3OCA3NzAuMSA5MTQuODUgODI1LjEwMyAxNDQwIDkzNi45OHYyNjAuNzYySDBWNzkzLjM5OHoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTc3MSkiIGZpbGw9InVybCgjYSkiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
		background-size: cover;
		padding: calc(100% * 427 / 1440);
	}
}

.donation-inner {
	position: relative; /* To display all elements above the background color */
}

.donation-paragraph {
    margin-bottom: 32px;
}

.donation-illustration {
    margin-top: 48px;

	img,
	svg {
		width: 100%;
		height: auto;
	}
}

@include media( '>medium' ) {

    .donation {
        padding-top: 127px;
        padding-bottom: 40px;

		&::before {
			bottom: 0;
			height: 577px;
			background-size: cover;
			padding: 0;
		}
    }

	.donation-paragraph {
		padding-left: 72px;
		padding-right: 72px;
	}

	.donation-illustration {
	    margin-top: 80px;
	}
}

@include media( '>large' ) {

	.donation {
	    position: relative;
	    padding-top: 140px;
	}
}