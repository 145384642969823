.navbar {
    padding: 0.3rem 1rem !important;
    z-index: 9;
    .navbar-brand {
        img {
            width: 70px;
            height: 50px;
        }
    }

    .nav-link {
        font-family: Roboto, sans-serif;
        font-size: 18px;

        cursor: pointer;
    }

    .dropdown-item {
        font-family: Roboto, sans-serif;
        font-size: 16px;
    }   
    .dropdown-item.active {
        background-color: #f8f9fa !important;
    }
}

.site-header-inner {
    position: relative; /* To display all elements above the background color */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.avatar {
    z-index: 9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    img {
        width: 48px;
        height: 48px;
        box-shadow: 0 5px 10px -5px rgba(0,0,0,0.5);
        border-radius: 50px;
    }
     
    span {
        font-size: 17px;
        color: white;
    }

    ul.site-header-menu {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 9;
        font-family: Roboto, sans-serif;
        padding: 0;
        margin: 0;

        li {
            list-style: none;
            margin-right: 32px;

            a {
                    text-decoration: none;
                    color: #131313;
                    font-size: 17px;
            }
        }
    }
}

.header-links {
    display: inline-flex;

    li {
        display: inline-flex;
    }

    a:not(.button) {
        @include font-family(heading);
        @include font-size(7, mobile, true, true, true);
        @if ( get-font-size(7, desktop) != get-font-size(7, mobile) ) {
            @include media( '>medium' ) {
                @include font-size(7, desktop, true, true, true);
            }
        }
        @include font-weight(bold);
        @include anchor-aspect(header);
        line-height: 16px;
        padding: 8px 24px;
    }
}
