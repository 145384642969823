/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.body_login{
    background-color: #1074bc !important;
}

ul {
  list-style: none !important;
}

li.topics svg {
  width: 15px;
  display: inline-block;
}

.body_login_adm {
  background-color: #1b1919 !important;
}

.body_home{
  background-color: #eef5f9;
}

html, body { height: 100%; }
body { margin: 0; font-family: 'Roboto', sans-serif; }

.mat-drawer-content {
  overflow: hidden !important;
}

#radio .mat-radio-container {
  top: 80px !important;
  left: 98px !important;
}

@import 'assets/scss/mixins/variables';
@import "assets/scss/mixins/functions";
@import "assets/scss/mixins/mixins";
@import 'assets/scss/mixins/include-media';


@import "assets/scss/normalize";
@import "assets/scss/fonts/line-icons.css";

/*--------------------------------------------------------------
# Base
--------------------------------------------------------------*/
@import "assets/scss/base/base";

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
@import "assets/scss/base/typography";

/*--------------------------------------------------------------
# Helpers
--------------------------------------------------------------*/
@import "assets/scss/base/helpers";

/*--------------------------------------------------------------
# Buttons
--------------------------------------------------------------*/
@import "assets/scss/components/buttons";

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
@import "assets/scss/layout/header";
@import "assets/scss/layout/heade"; /*** Page 2 **/

/*--------------------------------------------------------------
# Hero
--------------------------------------------------------------*/
@import "assets/scss/layout/features";

@import "assets/scss/layout/features-extended";

@import "assets/scss/layout/feature"; /**Page 2 **/
@import "assets/scss/layout/feature-tabs"; /**Page 2 **/

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
@import "assets/scss/layout/hero";

@import "assets/scss/layout/heros"; /** Page 2 **/

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
@import "assets/scss/layout/pricing";
@import "assets/scss/layout/pricing-faqs";

/*--------------------------------------------------------------
# Site content
--------------------------------------------------------------*/
@import "assets/scss/layout/main";

@import "assets/scss/layout/mains";

@import "assets/scss/layout/main-two"; /** Page 2 **/

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
@import "assets/scss/layout/testimonials";

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
@import "assets/scss/layout/footer";
